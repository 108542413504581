



































import bus from "@/bus";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class UserList extends Vue {
  public pageSize = 20
  public pageNum = 1
  public totality = 0
  public columns = [
    {
      title: 'MAC地址',
      dataIndex: 'deviceMac',
      align: "center",
      width: 250
    }, {
      title: '设备名称',
      dataIndex: 'deviceName',
      align: "center",
      width: 250
    }, {
      title: '设备数量',
      dataIndex: 'deviceNum',
      align: "center",
      width: 100
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      align: "center",
      width: 300
    }, {
      title: '所属用户',
      dataIndex: 'store.storeName',
      align: "center",
      width: 150
    }, {
      title: '最后连接时间',
      dataIndex: 'lastOnlineTime',
      align: "center",
      width: 200
    }, {
      title: '设备地址',
      dataIndex: 'store.address',
      align: "center",
      width: 300
    }, {
      title: '操作',
      dataIndex: 'operation',
      scopedSlots: {customRender: 'operation'},
      width: 150,
      align: "center"
    }
  ];
  public data = [];

  public mounted() {
    this.getUserList()
  }

  // 获取用户
  public getUserList() {
    bus.$post("/deviceMgr/deviceListPage", {
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }).then(res => {
      this.data = res.data.list
      this.totality = res.data.totalNum
    })
  }

  public onShowSizeChange(current, pageSize) {
    this.pageNum = current
    this.getUserList()
  }

  public onUnbind(k,data) {
    console.log(data);
  }
}
